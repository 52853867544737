*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.cuerpoimagenes{
    background-image: url("Imagenes/mina2.jpg");
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw;
    display: grid;
    max-height: 100vh; /* Altura máxima del contenedor */
    overflow-y: auto; /* Agregar scroll vertical */
    overflow-x: hidden; /* Ocultar scroll horizontal */
}

.q4x5grid{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 86vh;
    width: 100vw;
}

.q4x5{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    height: 86vh;
    max-height: 86vh; /* Altura máxima del contenedor */
    overflow-y: auto; /* Agregar scroll vertical */
    overflow-x: hidden; /* Ocultar scroll horizontal */
}

.item{
    margin: 5px;
    height: 220px;
    width: 270px;
    outline: 1px solid black;
    background-color: rgb(255, 255, 255, 0.8);
    position: relative;
    overflow: hidden;
    display: grid;
}

.item img{
    height: 160px;
    width: 270px;
    padding: 5px;
    cursor: pointer;
}

.item h1{
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}

.imagenAmpliada {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro semi-transparente */
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Poner encima de todo */
    cursor: pointer;
    text-align: center;
  }
  
  .imagenAmpliada img {
    max-width: 100%;
    max-height: 100%;
  }

  .imagenAmpliada h1 {
    position: absolute;
    bottom: 100px;
    display: flex;
    color: white;
    font-size: 14px;
    left: 50%; /* Centrar horizontalmente */
    transform: translateX(-50%); /* Centrar horizontalmente */
}

.filtrotablet{
    position: relative;
    display: flex;
    top: 0;
    background-color: black;
    color: gray;
    height: 5vh;
    width: 100%;
    text-align: center;
    align-items: center;
    place-content: center;
    z-index: 4;
}

.filtrotablet h1{
    font-size: 2vh;
    margin: 10px;
    cursor: pointer;
}

@media (max-width: 800px){
    .filtrotablet h1{
        font-size: 1vh
    }
  }

.filtrotablet h1:hover{
    margin: 10px;
    color: rgba(255, 0, 0, 0.7);
}

.redLine {
    text-decoration: underline;
    text-decoration-color: red;
    transition: width 2s, transform 2s;
    transform-origin: left;
    color: white;
}

#cerrartablet{
    position: absolute;
    right: 5px;
    cursor: pointer;
    top: 14%;
    z-index: 1;
}

@media (max-width: 768px){
    #cerrartablet{
        display: none
    }
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:focus {
    outline: none;
  }

  .banner-container {
    position: fixed;  /* Fija el contenedor en la parte superior */
    top: 0;           /* Se posiciona en la parte superior de la ventana */
    left: 0;          /* Se alinea a la izquierda */
    width: 100%;      /* El contenedor ocupa todo el ancho de la ventana */
    z-index: 9999;    /* Asegura que se quede por encima de otros elementos */
    background-color: #ae707000; /* Fondo blanco para el contenedor */
  }

  .historico-container {
    max-width: 80%;
    margin: 30px auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
  }
  
  .historico-container h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .mosaico-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    padding: 10px;
  }
  
  .mosaico-item {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .mosaico-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .mosaico-info {
    padding: 10px;
    font-size: 14px;
    text-align: left; 
  }
  
  .mosaico-info p {
    margin: 5px 0;
    color: #333;
  }

  .spinner {
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    height: 10vh; /* Ocupa toda la altura de la pantalla */
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 10px;
    margin: 0 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .pagination span {
    align-self: center;
    font-size: 16px;
  }
  .titulo-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Centra el contenido */
    position: relative; /* Permite posicionar el botón a la derecha */
  }
  
  .titulo-container h2 {
    flex: 1; /* Hace que ocupe el espacio central */
    text-align: center; /* Centra el texto */
  }
  
  .descargar-btn {
    position: absolute;
    right: 0; /* Lo alinea a la derecha */
    background-color: #f59e0b; /* Naranja */
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .descargar-btn:hover {
    background-color: #d97706; /* Tono más oscuro al pasar el mouse */
  }

  .button-map {
    padding: 4px 9px;
    cursor: pointer;
    margin-left: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .button-map:hover {
    background-color: #0056b3; /* Cambia el color al pasar el mouse */
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff0000; /* Fondo azul */
    color: white; /* Texto blanco */
    border: none;
    cursor: pointer;
    padding: 10px 15px; /* Espaciado alrededor del texto */
    font-size: 16px; /* Tamaño de fuente */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .modal-close:hover {
    background-color: darkblue; /* Color más oscuro al pasar el mouse */
  }
  
  .modal-content img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }